var _a;
import { __assign, __decorate, __extends } from "tslib";
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { ContainerQuery } from 'react-container-query';
import memoizedPartial from '@wix/wix-vod-shared/dist/src/common/utils/memoized-partial';
import { callOnEnterPressExactOnElement } from '../../utils/call-on-keyboard-press';
import { createGetPlayButtonConfig } from '../../../widget/layouts/compact/components/player-overlay/ui-selectors/video-overlay/play/button-config';
import { createGetPaidAccessButtonConfig } from '../../../widget/layouts/compact/components/player-overlay/ui-selectors/video-overlay/paid-access/button-config';
import { isVideoPlayingOptimistic } from '../../../widget/selectors/video-playback-status';
import { isLiveVideo } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/video-origin';
import { CurrencySign } from '@wix/wix-vod-shared/dist/src/common/components/currency-sign';
import { canShowVideoListItemTitle, canShowVideoListItemPublisher, isClassicLayout, } from '../../selectors/app-settings';
import LiveLabel from '../../../widget/containers/live-label/live-label';
import Title from '../../../widget/layouts/compact/components/player-overlay/partials/title';
import Publisher from '../../../widget/layouts/compact/components/player-overlay/partials/publisher';
import PlayButton from '../../../widget/layouts/compact/components/player-overlay/partials/play-button';
import VideoCover from '../video-cover/video-cover';
import { withStyles } from '../../utils/withStyles';
import styles from './video-thumbnail-overlay.styl';
// TODO: replace `getPlayButtonConfig` and `getPaidAccessButtonConfig` with `getPlayButtonText` approach
// TODO: add `currentSiteUser` selector to `@connect` and remove it from parent components props
// see https://github.com/wix-private/wix-vod/pull/2003#discussion_r148538134 for details
var containerQuery = (_a = {},
    _a[styles['element-max-199']] = {
        maxWidth: 199,
    },
    _a[styles['element-max-249']] = {
        minWidth: 200,
        maxWidth: 249,
    },
    _a);
var VideoThumbnailOverlay = /** @class */ (function (_super) {
    __extends(VideoThumbnailOverlay, _super);
    function VideoThumbnailOverlay() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.renderOverlay = function (queryClasses) {
            var _a, _b;
            var _c = _this.props, videoItem = _c.videoItem, className = _c.className, isInfoAlwaysShown = _c.isInfoAlwaysShown, isDescriptionBelow = _c.isDescriptionBelow, isInfoShownOnHover = _c.isInfoShownOnHover, isThumbnailsPreviewHover = _c.isThumbnailsPreviewHover, isSelected = _c.isSelected, isPlaying = _c.isPlaying, width = _c.width, height = _c.height, isContentFocusable = _c.isContentFocusable, onClick = _c.onClick, isLive = _c.isLive;
            if (!videoItem) {
                return null;
            }
            var classNames = classnames(styles.container, className, __assign((_a = {}, _a[styles['info-visible']] = isInfoAlwaysShown, _a[styles['info-when-hover']] = isInfoShownOnHover, _a[styles['description-below']] = isDescriptionBelow, _a[styles['preview-hover']] = isThumbnailsPreviewHover, _a[styles.selected] = isSelected, _a[styles.playing] = isPlaying, _a), queryClasses));
            var thumbClassNames = classnames(styles.thumbnail, (_b = {},
                _b[styles.live] = isLive,
                _b));
            var dataHook = classnames('video-list-thumb-wrapper', {
                'video-list-thumb-wrapper-selected': isSelected,
            });
            return (React.createElement("section", { className: classNames, onClick: onClick, onKeyDown: memoizedPartial(callOnEnterPressExactOnElement, onClick), "data-hook": dataHook, style: { width: width }, "aria-hidden": !isContentFocusable, tabIndex: isContentFocusable ? 0 : -1 },
                React.createElement("div", { className: thumbClassNames, style: { height: height, width: width } },
                    React.createElement(VideoCover, { className: styles.cover, videoItem: videoItem, breakpoints: [{ min: 0, width: width, height: height }], withFallbackColor: true }),
                    React.createElement("div", { className: styles.content },
                        _this.renderPlayingIndication(),
                        !isDescriptionBelow && _this.renderTitle(),
                        _this.renderPaidAccessIcon(),
                        _this.renderDuration(),
                        _this.renderPlayButton(),
                        _this.renderLiveLabel())),
                isDescriptionBelow && _this.renderDescription()));
        };
        return _this;
    }
    VideoThumbnailOverlay.prototype.renderTitle = function () {
        var _a = this.props, videoItem = _a.videoItem, isPlaying = _a.isPlaying, isDescriptionBelow = _a.isDescriptionBelow, isShowVideoListItemTitle = _a.isShowVideoListItemTitle, isClassicLayout = _a.isClassicLayout;
        return isClassicLayout && !isShowVideoListItemTitle ? null : (React.createElement(Title, { className: styles.title, maxLinesCount: isDescriptionBelow ? 1 : 2, ariaHidden: isPlaying }, videoItem.title));
    };
    VideoThumbnailOverlay.prototype.renderPublisher = function () {
        var _a = this.props, videoItem = _a.videoItem, isPlaying = _a.isPlaying, isClassicLayout = _a.isClassicLayout, isShowVideoListItemPublisher = _a.isShowVideoListItemPublisher;
        return isClassicLayout && !isShowVideoListItemPublisher ? null : (React.createElement(Publisher, { className: styles.publisher, ariaHidden: isPlaying }, videoItem.publisher));
    };
    VideoThumbnailOverlay.prototype.renderDescription = function () {
        var title = this.renderTitle();
        var publisher = this.renderPublisher();
        if (!title && !publisher) {
            return null;
        }
        return (React.createElement("div", { className: styles.description },
            title,
            publisher));
    };
    VideoThumbnailOverlay.prototype.renderPlayButton = function () {
        var _a = this.props, playButtonConfig = _a.playButtonConfig, isContentFocusable = _a.isContentFocusable, isPlaying = _a.isPlaying;
        if (!playButtonConfig) {
            return null;
        }
        return (React.createElement(PlayButton, { className: styles['play-button'], iconClassName: styles['play-icon'], onClick: playButtonConfig.callback, isFocusable: isContentFocusable, ariaHidden: isPlaying, isIconOnly: true }, i18n.t(playButtonConfig.translationData.props)));
    };
    VideoThumbnailOverlay.prototype.renderPaidAccessIcon = function () {
        var _a = this.props, paidAccessButtonConfig = _a.paidAccessButtonConfig, isPlaying = _a.isPlaying, videoItem = _a.videoItem;
        if (!paidAccessButtonConfig) {
            return null;
        }
        var currency = videoItem.dealInfo[0] && videoItem.dealInfo[0].currency;
        return (React.createElement("div", { className: styles['paid-access-icon'], "aria-hidden": isPlaying, "data-hook": "paid-icon" },
            React.createElement(CurrencySign, { currency: currency })));
    };
    VideoThumbnailOverlay.prototype.renderPlayingIndication = function () {
        var isPlaying = this.props.isPlaying;
        return (React.createElement("div", { className: styles['now-playing'], tabIndex: -1, "aria-hidden": !isPlaying }, i18n.t('thumbnail.now-playing')));
    };
    VideoThumbnailOverlay.prototype.renderDuration = function () {
        var _a = this.props, isLive = _a.isLive, _b = _a.videoItem, durationStr = _b.durationStr, isPlaying = _b.isPlaying;
        if (!durationStr || isLive) {
            return null;
        }
        return (React.createElement("div", { className: styles.duration, role: "timer", "aria-hidden": isPlaying, "aria-label": i18n.t('widget.accessibility.duration', { durationStr: durationStr }) }, durationStr));
    };
    VideoThumbnailOverlay.prototype.renderLiveLabel = function () {
        var videoItem = this.props.videoItem;
        return (React.createElement(LiveLabel, { itemType: videoItem.itemType, liveVideoStatus: videoItem.liveVideoStatus, startTime: videoItem.dateStartLive, isSmall: true, className: styles['live-label'], smallClassName: styles['live-scheduled-label'] }));
    };
    VideoThumbnailOverlay.prototype.render = function () {
        return (React.createElement(ContainerQuery, { query: containerQuery }, this.renderOverlay));
    };
    VideoThumbnailOverlay.propTypes = {
        videoItem: PropTypes.object.isRequired,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        isThumbnailsPreviewHover: PropTypes.bool.isRequired,
        isInfoAlwaysShown: PropTypes.bool.isRequired,
        isInfoShownOnHover: PropTypes.bool.isRequired,
        isSelected: PropTypes.bool.isRequired,
        isPlaying: PropTypes.bool.isRequired,
        isLive: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
        onPlayRequest: PropTypes.func.isRequired,
        onPlayMemberOnlyRequest: PropTypes.func.isRequired,
        onPlayWithoutPreviewRequest: PropTypes.func.isRequired,
        onPurchaseRequest: PropTypes.func.isRequired,
        onRentRequest: PropTypes.func.isRequired,
        onSubscriptionRequest: PropTypes.func.isRequired,
        className: PropTypes.string,
        currentSiteUser: PropTypes.object,
        playButtonConfig: PropTypes.object,
        paidAccessButtonConfig: PropTypes.object,
        withFallbackColor: PropTypes.bool,
        isContentFocusable: PropTypes.bool,
        isDescriptionBelow: PropTypes.bool,
        isShowVideoListItemTitle: PropTypes.bool,
        isShowVideoListItemPublisher: PropTypes.bool,
        isClassicLayout: PropTypes.bool,
    };
    VideoThumbnailOverlay.defaultProps = {
        isContentFocusable: true,
        isDescriptionBelow: false,
        isShowVideoListItemTitle: true,
        isShowVideoListItemPublisher: true,
    };
    VideoThumbnailOverlay = __decorate([
        connect(function () {
            var getPlayButtonConfig = createGetPlayButtonConfig();
            var getPaidAccessButtonConfig = createGetPaidAccessButtonConfig();
            return function (state, ownProps) { return ({
                playButtonConfig: getPlayButtonConfig(state, ownProps),
                paidAccessButtonConfig: getPaidAccessButtonConfig(state, ownProps),
                isLive: isLiveVideo(ownProps.videoItem),
                isPlaying: ownProps.isSelected && isVideoPlayingOptimistic(state),
                isShowVideoListItemTitle: canShowVideoListItemTitle(state),
                isShowVideoListItemPublisher: canShowVideoListItemPublisher(state),
                isClassicLayout: isClassicLayout(state),
            }); };
        }),
        withStyles(styles)
    ], VideoThumbnailOverlay);
    return VideoThumbnailOverlay;
}(React.Component));
export default VideoThumbnailOverlay;
