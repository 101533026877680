import { __awaiter, __generator } from "tslib";
import WixSDK from '../../../../shared/utils/wix-sdk';
import { getCompId } from '../../../../shared/utils/comp-id';
import { getInstance } from '../hydrated-data/hydrated-data';
import PAYMENT_MESSAGE_PROXY from '../../../../shared/constants/payment-message-proxy';
import URL from '@wix/wix-vod-shared/dist/src/common/utils/url';
import { isGraphAPIEnabled } from '@wix/wix-vod-shared/dist/src/common/utils/experiments';
import { CREATE_ORDER, INIT_CHECKOUT, SELECT_METHOD, setError, } from './payment.constants';
import { createAsyncProxy } from '../../../../shared/worker/lib';
import { publicApi } from '../../../../api/public';
import { pubSubPublish } from '../../../../shared/worker/actions/pub-sub/publish';
import EVENTS from '../../../../shared/constants/events';
var getSiteDeepUrl = function () {
    return WixSDK.Settings.getSiteInfo().then(function (siteInfo) {
        var _a = new URL(siteInfo.url, {
            skipValidation: true,
        }), protocol = _a.protocol, hostname = _a.hostname, pathname = _a.pathname;
        return protocol + "//" + hostname + pathname;
    });
};
export * from './payment.constants';
export function createOrder(paymentType, channelId, videoId) {
    return function (dispatch, getState) {
        var state = getState();
        var instance = getInstance(state);
        var compId = getCompId();
        dispatch(CREATE_ORDER.START({ paymentType: paymentType, channelId: channelId, videoId: videoId }));
        return getSiteDeepUrl()
            .then(function (siteUrl) {
            return publicApi.purchase.createOrder(videoId, channelId, paymentType, siteUrl, instance, compId);
        })
            .then(function (orderId) {
            dispatch(CREATE_ORDER.SUCCESS({ orderId: orderId }));
        }, function (error) {
            dispatch(CREATE_ORDER.FAIL(error));
            return Promise.reject(error);
        });
    };
}
export var cancelOrder = function (currentChannelId, siteUrl, instance) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, publicApi.purchase.cancelOrder(currentChannelId, siteUrl, instance)];
            case 1:
                _a.sent();
                if (isGraphAPIEnabled()) {
                    dispatch(pubSubPublish(EVENTS.PAYMENT.PAYMENT_EVENT, {
                        message: {
                            completed: true,
                            type: PAYMENT_MESSAGE_PROXY.TYPES.SUBSCRIPTION_CANCEL,
                        },
                    }));
                }
                return [2 /*return*/];
        }
    });
}); }; };
export var selectPaymentMethod = function (paymentMethod) {
    return SELECT_METHOD(paymentMethod);
};
export var initPayment = createAsyncProxy('payment.initPayment', function (_a) {
    var paymentType = _a.paymentType, channelId = _a.channelId, videoId = _a.videoId;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    dispatch(INIT_CHECKOUT.START());
                    return [4 /*yield*/, dispatch(createOrder(paymentType, channelId, videoId))];
                case 1:
                    _a.sent();
                    dispatch(INIT_CHECKOUT.SUCCESS());
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    dispatch(INIT_CHECKOUT.FAIL(error_1));
                    dispatch(setError(error_1));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
});
